import apiInstance from './configApis'

export const uploadElevationImage = async (body) => {
  try {
    const { data } = await apiInstance.post(`upload/upload-signed-url-image`, body)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

export const deleteFiles = async (url_arr) => {
  try {
    const { data } = await apiInstance.put(`upload/delete-images`, url_arr)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

export const aiAutoMasking = async (body) => {
  try {
    const { data } = await apiInstance.post(`ai/auto-masking-v2`, body)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

export const maskingElevationImage = async (elevation_id) => {
  try {
    const data = await apiInstance.post(`ai/detect`, { elevation_id })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
