/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import imageCompression from 'browser-image-compression'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import EXIF from 'exif-js'
import { uploadFileAWS } from '../../../utils/s3'
import { aiAutoMasking, uploadElevationImage } from '../../../apis/fileApis'
import useAuth from '../../../hooks/useAuth'
import useCloseRange from '../../../hooks/useCloseRange'
import usePrevious from '../../../hooks/usePrevious'
import {
  editElevation,
  getElevationDetails,
  deleteElevation,
  createAnnotationDroneElevation,
  createAnnotationUserElevation,
  createAnnotationCloseRangeElevation,
  createCloseRangeInThumbnail,
  updateCheckedDroneAnnotationElevation,
  updateStatusCloseRangeDrone,
  updateStatusCloseRangeManual,
  deleteCloseRangeElevation,
  setElevationStatus,
  maskingElevation,
  updateElevationThumbnail,
  resetElevation
} from '../../../store/thunks/elevationThunk'
import { changeFileName, deepEqual, makeRoles, dataURItoBlob, handleCreateCloseupImage } from '../../../utils/common'

import { convertFileToBase64, dataURLtoFile } from '../../../utils/fileUtils'
import { getBuildingDetails } from '../../../store/thunks/buildingThunk'
import ElevationInfo from './ElevationInfo'
import ImagePopUp from './Popup/ImagePopUp'
import ImageCloseRangePopUp from './Popup/ImageCloseRangePopUp'
import ImageContainer from './ImageContainer'
import ImageUserList from './ImageUserList'
import ImageThermalList from './ImageThermalList'
import ImageCloseRangeList from './ImageCloseRangeList'
import CustomReplaceImage from '../../Common/upload-image/CustomReplaceImage.js'
import { toast } from 'react-toastify'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import inspectionTypes from '../../../constant/store/inspectionTypes'
import ReactImageAnnotate from 'react-image-annotate-custom-region-edit-label-ver-2'
import CustomRegionLabelOnceColor from '../CustomLabelAnnotation/CustomRegionLabelOnceColor/index.js'
import { EditOutlined } from '@ant-design/icons'
import ImageUserPopUp from './Popup/ImageUserPopUp'
import elevationTypes from '../../../constant/store/elevationTypes'
import NotAvailable from '../../Common/NotAvailable'
import PopupAddImageType from './Popup/PopupAddImageType'
import Navigate from '../../Common/Navigate'
import LoadingSpinner from '../../Common/loading-spinner/LoadingSpinner'
import SkeletonViewer from '../../Common/skeleton-viewer'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Collapse } from 'antd'
import SmartDrawer from '../../Common/drawer/SmartDrawer'
import CustomRegionLabel from '../CustomLabelAnnotation/CustomRegionLabel'
import { getAllCompanyReason } from '../../../store/thunks/reasonThunk'
import { getAllCompanyRepair } from '../../../store/thunks/repairThunk'
import { color } from '../../../constant/styled'
import ElevationImageLogList from './ElevationImageLogList'
import { getDefectCode } from '../../../utils/stringUtil'
import useIdleTimer from '../../../hooks/useIdleTimer'

// assets
import CommonTooltip from '../../Common/tooltip/CommonTooltip.js'
import PaddingAuto from '../../Layout/PaddingAuto.js'
import Modal from '../../Common/Modal/index.js'
import DrawCanvasOnImage from '../../custom/DrawCanvasOnImage.js'

const { Panel } = Collapse
const optionNavigate = [
  { id: 1, title: 'Inspection', canNavigate: true, path: `/inspections` },
  { id: 2, title: 'Elevation detail', canNavigate: false }
]
const options = {
  maxSizeMB: 1,
  initialQuality: 0.6,
  maxWidthOrHeight: 1920
}

const ElevationDetails = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { role } = user?.user || {}
  const {
    closeRangeImage,
    setCloseRangeImage,
    closeRangeDefectId,
    setCloseRangeDefectId,
    setImageItem,
    imageCloseRangeOnDrawer,
    setImageCloseRangeOnDrawer
  } = useCloseRange()

  const { id } = useParams()
  const dispatch = useDispatch()
  const { reset } = useIdleTimer()

  const {
    contents: contentsElevation,
    errors: errorsElevation,
    loadings: loadingsElevation
  } = useSelector((state) => state.elevation)

  const { contents } = useSelector((state) => state.building)
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const [showCloseRangeOndrawer, setShowCloseRangeOndrawer] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [columns, setColumns] = useState()
  const [rows, setRows] = useState()
  const [manualRow, setManualRow] = useState(0)
  const [direction, setDirection] = useState(0)
  const [listImgs, setListImgs] = useState([])
  const [position, setPosition] = useState([])
  const [manualPosition, setManualPosition] = useState([])
  const [popUpPosition, setPopUpPosition] = useState()
  const [listAnnotates, setListAnnotatesDrone] = useState([])
  const [listCountColorAnnotates, setListCountColorAnnotates] = useState([])
  const [annotate, setAnnotate] = useState([])
  const [selected, setSelected] = useState(null)
  let [count, setCount] = useState(0)
  const [totalNewItem, setTotalNewItem] = useState(0)
  const [isShowProcess, setIsShowProcess] = useState(false)
  const [thumbnail, setThumbnail] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [reviewOnThumbnail, setReviewOnThumbnail] = useState([])
  const [listThermalImages, setListThermalImages] = useState([])
  const [listManualImages, setListManualImages] = useState([])
  const [listReviewManualImages, setListReviewManualImages] = useState([])
  const [listImageCloseRangeDrone, setListImageCloseRangeDrone] = useState([])
  const [listImageCloseRangeManual, setListImageCloseRangeManual] = useState([])
  const [listImageCloseRangeThumbnail, setListImageCloseRangeThumbnail] = useState([])
  const thumbnailRef = useRef()
  const imageUploadRef = useRef()
  const [thumbnailH, setThumbnailH] = useState()
  const [thumbnailW, setThumbnailW] = useState(300)
  const [showThermalImage, setShowThermalImage] = useState()
  const [logDrawer, setShowLogDrawer] = useState(null)
  const [popUpManualImage, setPopUpManualImage] = useState('down')
  const [popUpThumbnail, setPopUpThumbnail] = useState('down')
  const [popUpCloseRangeThumbnail, setPopUpCloseRangeThumbnail] = useState('down')
  const [popUpImageCloseRange, setPopUpImageCloseRange] = useState('down')
  const [lockSaveBtn, setLockSaveBtn] = useState(true)

  const [savedAnnotation, setSavedAnnotation] = useState([])
  const [listArrowRow, setListArrowRow] = useState([])
  const [popupAddImageType, setPopupAddImageType] = useState(false)
  const [switcherMessage, setSwitcherMessage] = useState('')
  const [_mounted, setMounted] = useState(false)
  const [cameraAddByThumbnail, setCameraAddByThumbnail] = useState(false)
  const prevElevationDeleteFetching = usePrevious(loadingsElevation[elevationTypes.DELETE_ELEVATION])
  const prevElevationGetFetching = usePrevious(loadingsElevation[elevationTypes.GET_ELEVATION_DETAILS])
  const prevElevationUpdateFetching = usePrevious(loadingsElevation[elevationTypes.UPDATE_ELEVATION])
  const prevElevationUpdateThumbnail = usePrevious(loadingsElevation[elevationTypes.UPDATE_ELEVATION_THUMBNAIL])
  const prevStatusCloseRangDroneFetching = usePrevious(
    loadingsElevation[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE]
  )
  const prevStatusCloseRangManualFetching = usePrevious(
    loadingsElevation[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL]
  )
  const prevElevationCreateAnnotationDrone = usePrevious(
    loadingsElevation[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION]
  )
  const prevElevationCreateAnnotationUser = usePrevious(
    loadingsElevation[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION]
  )
  const prevElevationUpdateCheckedAnnotationDrone = usePrevious(
    loadingsElevation[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION]
  )

  const prevElevationCreateAnnotationCloseRange = usePrevious(
    loadingsElevation[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION]
  )

  const prevElevationCreateCloseRangeInThumbnail = usePrevious(
    loadingsElevation[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL]
  )

  const prevDeleteCloseRangeElevation = usePrevious(loadingsElevation[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION])
  const prevSetStatusElevation = usePrevious(loadingsElevation[elevationTypes.SET_ELEVATION_STATUS])

  const prevAIMaskingElevation = usePrevious(loadingsElevation[elevationTypes.AI_MASKING_ELEVATION])

  const elevationDetails = contentsElevation[elevationTypes.GET_ELEVATION_DETAILS]
  const buildingDetails = contents[inspectionTypes.GET_BUILDINGS_DETAILS]

  const [floors, setFloors] = useState('')
  const [gridline, setGridline] = useState('')

  const elevationTab = [
    { tabIndex: 1, name: 'DRONE IMAGES', title: 'Images captured by drone (max 400 images)', path: 'drone' },
    { tabIndex: 4, name: 'THERMAL IMAGES', title: 'Thermal images captured by drone', path: 'thermal' },
    { tabIndex: 2, name: 'MANUAL IMAGES', title: 'All non-drone images', path: 'manual' },
    { tabIndex: 3, name: 'CLOSE RANGE IMAGES', path: 'thumbnail' }
  ]

  const colorsDrawer = [
    {
      color: '#FF0000',
      count: 0
    },
    {
      color: '#FFFF00',
      count: 0,
      include: 0
    },
    {
      color: '#00FF00',
      count: 0,
      include: 0
    },
    {
      color: '#00FFFF',
      count: 0
    }
  ]

  useMemo(() => {
    setMounted(true)
  }, [])

  const openTab = useMemo(() => {
    if (location.pathname.includes('/drone')) return 1
    if (location.pathname.includes('/thermal')) return 4
    if (location.pathname.includes('/manual')) return 2
    if (location.pathname.includes('/thumbnail')) return 3
  }, [location.pathname])

  const filterReviewAnnotation = useMemo(
    () =>
      [...(listImgs.filter((img) => img.order === showThermalImage?.order)[0]?.review || [])].filter(
        (r) => !r.fillColor
      ),
    [showThermalImage, listImgs]
  )

  const isImageThermal = useMemo(
    () =>
      listThermalImages.filter((thermal) => thermal.order === showThermalImage?.order)[0]?.urlOriginal ||
      listThermalImages.filter((thermal) => thermal.order === showThermalImage?.order)[0]?.url,
    [listThermalImages, showThermalImage]
  )
  // get data table

  const handleNavigateParamsUrl = (params, replace = false, pathname, state = null) => {
    navigate(
      {
        pathname: pathname,
        search: createSearchParams({
          type: params.type,
          id: params.id,
          page: params.page,
          size: params.size,
          ...params
        }).toString()
      },
      { replace, state }
    )
  }

  const handleNumberFloor = (evt) => {
    var charCode = evt.keyCode
    if (floors === '' && charCode === 48) {
      evt.preventDefault()
    }
    if (evt.shiftKey) {
      evt.preventDefault()
    }

    if (charCode !== 8 && charCode !== 9 && charCode !== 16 && (charCode < 48 || charCode > 57)) evt.preventDefault()
  }

  const handleStringGridline = (evt) => {
    var charCode = evt.keyCode
    if (evt.shiftKey) {
      if (charCode < 65 || charCode > 90) {
        evt.preventDefault()
      }
    }
    if (charCode !== 8 && charCode !== 9 && (charCode < 65 || charCode > 90) && (charCode < 48 || charCode > 57))
      evt.preventDefault()
  }

  const handleChangeFloor = (e) => {
    if (e.target.value.length <= 3 && +e.target.value <= 200) {
      setFloors(e.target.value)
    }
  }

  const handleChangeGridline = (e) => {
    if (e.target.value.length <= 3) {
      setGridline(e.target.value)
    }
  }

  const onLoad = async () => {
    setThumbnailH(thumbnailRef.current?.offsetHeight)
    setThumbnailW(thumbnailRef.current?.offsetWidth)
  }

  const onChangeElevationStatus = (name, status) => {
    setSubmitting(true)
    let message = ''
    if (name === 'visual' && status === true) {
      message = 'Visual inspection is now locked'
    } else if (name === 'visual' && status === false) {
      message = 'Visual inspection is now unlocked'
    } else if (name === 'close_range' && status === true) {
      message = 'Close-range inspection is now locked'
    } else if (name === 'close_range' && status === false) {
      message = 'Close-range inspection is now unlocked'
    }
    setSwitcherMessage(message)
    dispatch(setElevationStatus({ elevation_id: elevationDetails.elevation.id, type: name, switchBol: status }))
  }

  const handleThumbnail = async () => {
    if (openTab === 1 || openTab === 2) {
      setThumbnail(elevationDetails?.elevation?.data_thumbnail?.thumbnailUrl)
    } else {
      const c = document.getElementById('canvasThumbnail')
      let canvasUrl = await c.toDataURL('image/jpeg')
      setThumbnail(canvasUrl)
    }
  }

  const handleCreateCanvasFullImage = async (image, canvas) => {
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    var img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = async function () {
      canvas.width = img.width
      canvas.height = img.height
      var t = Math.floor(canvas.width / 100)
      var w = Math.floor(t / 2)

      await ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      ctx.lineWidth = w
      image.review.forEach((r) => {
        if (r.type === 'box') {
          ctx.strokeStyle = r.color
          ctx.strokeRect(r.x * canvas.width, r.y * canvas.height, r.w * canvas.width, r.h * canvas.height)
        } else if (r.type === 'point') {
          ctx.beginPath()
          ctx.fillStyle = r.color
          ctx.arc(r.x * canvas.width, r.y * canvas.height, t * 2, 0, 2 * Math.PI)
          ctx.fill()
        }
      })
    }
    img.src = image.url + '?stopGivingMeHeadaches=true'
    await img.decode()
    return 0
  }

  const handleExportTxTnImage = async () => {
    setExportLoading(true)
    var listAnnotatesSort = []
    for (const img of listImgs) {
      const findIndex = listAnnotates.map((i) => i.url).indexOf(img.url)
      if (findIndex !== -1) {
        listAnnotatesSort.push(listAnnotates[findIndex])
      }
    }

    try {
      var zip = new JSZip()
      for (const img of listAnnotatesSort) {
        const c = document.getElementById('canvas')
        const imageName = img.url.slice(img.url.lastIndexOf('/') + 1, -4)
        var text = ''
        if (img.review && img.review.length > 0) {
          for (const review of img.review) {
            let elementType = review.element
            let defectType = review.tags
            let defectCode = getDefectCode(defectType, elementType)

            text += `${defectCode} ${review.x_yolo} ${review.y_yolo} ${review.w} ${review.h} \n`
          }
          zip.file(`${imageName}.txt`, text)
          const canvas = document.getElementById('canvas')
          await handleCreateCanvasFullImage(img, canvas)
          let canvasUrl = await c.toDataURL('image/jpeg', 0.5)
          let image = dataURItoBlob(canvasUrl)
          zip.file(`${imageName}.png`, image)
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `image_yolo_annotation.zip`)
      })
      setExportLoading(false)
    } catch (error) {
      setExportLoading(false)
      toast.error(error)
    }
  }

  const handleExportTxT = () => {
    var listAnnotatesSort = []
    for (const img of listImgs) {
      const findIndex = listAnnotates.map((i) => i.url).indexOf(img.url)
      if (findIndex !== -1) {
        listAnnotatesSort.push(listAnnotates[findIndex])
      }
    }

    var zip = new JSZip()
    for (const img of listAnnotatesSort) {
      const imageName = img.url.slice(img.url.lastIndexOf('/') + 1, -4)
      var text = ''
      if (img.review && img.review.length > 0) {
        for (const review of img.review) {
          let elementType = review.element
          let defectType = review.tags
          let defectCode = getDefectCode(defectType, elementType)

          text += `${defectCode} ${review.x_yolo} ${review.y_yolo} ${review.w} ${review.h} \n`
        }
        zip.file(`${imageName}.txt`, text)
        const file = new Blob([text], { type: 'text/plain' })
        uploadFileAWS(file, `${imageName}.txt`)
      }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `yolo_annotation.zip`)
    })
  }

  const setData = async () => {
    setColumns(elevationDetails.elevation.columns)
    setDirection(elevationDetails.elevation.direction)
    setRows(elevationDetails.elevation.rows)
    setListImgs(elevationDetails?.elevation?.images)
    setListThermalImages(elevationDetails?.elevation?.thermal_images || [])
    setManualRow(elevationDetails?.elevation?.manual_rows || 0)
    setListManualImages(elevationDetails?.elevation?.manual_images || [])
    setListImageCloseRangeDrone(elevationDetails?.closeRangeImages.filter((item) => item.type === 'Drone'))
    setListImageCloseRangeManual(elevationDetails?.closeRangeImages.filter((item) => item.type === 'Manual'))
    setListImageCloseRangeThumbnail(elevationDetails?.closeRangeImages.filter((item) => item.type === 'Thumbnail'))
    let listAnnotatesDrone = []
    let listAnnotatesManual = []
    listAnnotatesDrone =
      elevationDetails?.elevation?.images
        ?.filter((i) => i.review)
        ?.map((item) => {
          return {
            url: item.url,
            review: item.review,
            defects: item.defects,
            closeRangeDefects: item.closeRangeDefects,
            urlOriginal: item.urlOriginal
          }
        }) || []

    listAnnotatesManual =
      elevationDetails?.elevation?.manual_images
        ?.filter((i) => i.review)
        ?.map((item) => ({
          url: item.url,
          review: item.review,
          closeRangeDefects: item.closeRangeDefects,
          urlOriginal: item.urlOriginal
        })) || []

    setListAnnotatesDrone(listAnnotatesDrone)
    setListCountColorAnnotates(
      listAnnotatesDrone.map((item) => ({
        url: item.url,
        defects: item.defects,
        closeRangeDefects: item.closeRangeDefects
      }))
    )
    setListReviewManualImages(listAnnotatesManual)
    setThumbnail(elevationDetails?.elevation?.data_thumbnail?.thumbnailUrl)
    if (elevationDetails.elevation.data_thumbnail?.closeRange?.length > 0) {
      const canvas = document.getElementById('canvasThumbnail')
      await handleCreateCanvasFullImage(
        {
          url: elevationDetails?.elevation?.data_thumbnail?.thumbnailUrl,
          review: [...elevationDetails?.elevation?.data_thumbnail?.closeRange]
        },
        canvas
      )
      if (openTab === 3) {
        const c = document.getElementById('canvasThumbnail')
        let canvasUrl = await c.toDataURL('image/jpeg')
        setThumbnail(canvasUrl)
      }
    } else {
      const canvas = document.getElementById('canvasThumbnail')
      await handleCreateCanvasFullImage(
        {
          url: elevationDetails?.elevation?.data_thumbnail?.thumbnailUrl,
          review: []
        },
        canvas
      )
    }
  }

  const handleUploadNewImagePreview = (imageList, position) => {
    handleAddNewImage(imageList[0], position)
  }

  const handleReplaceNewImagePreview = (imageList, position) => {
    setShowThermalImage({ ...showThermalImage, urlOriginal: imageList[0].data_url })
    handleReplaceImage(imageList[0], position)
  }

  const handleReplaceThermalImage = (newItem, positionAdd) => {
    if (listThermalImages.length > 0) {
      let newThermalImgs = [...listThermalImages]

      const findIndex = newThermalImgs.map((i) => i.order).indexOf(positionAdd)
      let newThermalImage = {
        order: positionAdd,
        url: newItem.data_url,
        file: newItem.file,
        new: true
      }
      newThermalImgs.splice(findIndex, 1, newThermalImage)
      setListThermalImages(newThermalImgs)
      setLockSaveBtn(false)
    }
  }

  const handleReplaceNewThermalImage = (imageList, position) => {
    setShowThermalImage({ ...showThermalImage, thermalUrlOrigin: imageList[0].data_url })
    handleReplaceThermalImage(imageList[0], position)
  }

  const handleSaveNewImages = async () => {
    const updatedListImgs = [...listImgs]
    const updatedListThermalImgs = [...listThermalImages]

    const updatedListManualImages =
      [...listManualImages].map(({ review, ...rest }) => {
        return { ...rest }
      }) || []

    let numImages = 0

    setIsShowProcess(true)
    try {
      const total = updatedListImgs.filter((item) => item.new)
      const totalThermal = updatedListThermalImgs.filter((item) => item.new)
      const totalManualImages = updatedListManualImages.filter((item) => item.new)
      const totalImages = totalManualImages.length + total.length + totalThermal.length
      setTotalNewItem(totalImages)
      for (const item of updatedListImgs) {
        if (item.new) {
          // compress image
          const nanoiId = nanoid()
          let newFileName = changeFileName(nanoiId + item.file.name, 'jpg')
          let fileName = decodeURIComponent(item.file.name)
          let blob = item.file.slice(0, item.file.size, 'image/jpeg')
          let newFile = new File([blob], newFileName, { type: 'image/jpeg' })
          let dataThumbnail = {
            filename: newFile.name,
            contentType: newFile.type,
            key: 'elevation',
            type: 'compressed',
            elevationId: elevationDetails.elevation.id,
            size: item.file.size
          }
          let { signedUrl } = await uploadElevationImage(dataThumbnail)
          let maskingUrl = {}
          const compressedFile = await imageCompression(newFile, options)
          // save image to s3
          await fetch(signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': newFile.type
            },
            body: compressedFile
          })
          maskingUrl = await aiAutoMasking({
            elevation_id: elevationDetails.elevation.id,
            type: 'drone',
            url: signedUrl.split('?')[0]
          })
          reset()
          numImages += 1
          setCount(numImages)

          item.url = maskingUrl.newUrl
          // item.url = signedUrl.split('?')[0]

          //original image
          let newFileNameOriginal = changeFileName('original_' + nanoiId + item.file.name, 'jpg')
          let newFileOriginal = new File([blob], newFileNameOriginal, { type: 'image/jpeg' })
          let dataThumbnailOrg = {
            filename: newFileOriginal.name,
            contentType: newFileOriginal.type,
            key: 'elevation',
            type: 'raw',
            elevationId: elevationDetails.elevation.id,
            size: item.file.size
          }
          // let originalSigned = await uploadElevationImage(newFileOriginal.name, newFileOriginal.type)
          let originalSigned = await uploadElevationImage(dataThumbnailOrg)
          await fetch(originalSigned.signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': newFileOriginal.type
            },
            body: newFileOriginal
          })
          item.urlOriginal = originalSigned.signedUrl.split('?')[0]
          item.fileName = fileName
          let metadata = await new Promise((resolve) =>
            EXIF.getData(item.file, function () {
              const exifData = {
                DateTimeOriginal: EXIF.getTag(this, 'DateTimeOriginal'),
                GPSLatitudeRef: EXIF.getTag(this, 'GPSLatitudeRef'),
                GPSLatitude: EXIF.getTag(this, 'GPSLatitude'),
                GPSLongitudeRef: EXIF.getTag(this, 'GPSLongitudeRef'),
                GPSLongitude: EXIF.getTag(this, 'GPSLongitude'),
                GPSAltitude: EXIF.getTag(this, 'GPSAltitude'),
                GPSAltitudeRef: EXIF.getTag(this, 'GPSAltitudeRef')
              }
              resolve(exifData)
            })
          )
          if (!(JSON.stringify(metadata) === '{}')) {
            item.metadata = metadata
          }

          delete item.new
          delete item.file
        }
      }

      for (const item of updatedListThermalImgs) {
        if (item.new) {
          // compress image
          const nanoiId = nanoid()
          let newFileName = changeFileName(nanoiId + item.file.name, 'jpg')
          let fileName = decodeURIComponent(item.file.name)
          let blob = item.file.slice(0, item.file.size, 'image/jpeg')
          let newFile = new File([blob], newFileName, { type: 'image/jpeg' })
          let dataThumbnail = {
            filename: newFile.name,
            contentType: newFile.type,
            key: 'elevation',
            type: 'compressed',
            elevationId: elevationDetails.elevation.id,
            size: item.file.size
          }
          // let { signedUrl } = await uploadElevationImage(newFile.name, newFile.type)
          let { signedUrl } = await uploadElevationImage(dataThumbnail)
          // let maskingUrl = {}
          const compressedFile = await imageCompression(newFile, options)
          // save image to s3
          await fetch(signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': newFile.type
            },
            body: compressedFile
          })
          // maskingUrl = await aiAutoMasking({
          //   elevation_id: elevationDetails.elevation.id,
          //   url: signedUrl.split('?')[0]
          // })

          reset()
          numImages += 1
          setCount(numImages)

          item.url = signedUrl.split('?')[0]

          //original image
          let newFileNameOriginal = changeFileName('original_' + nanoiId + item.file.name, 'jpg')
          let newFileOriginal = new File([blob], newFileNameOriginal, { type: 'image/jpeg' })
          let dataThumbnailOrg = {
            filename: newFileOriginal.name,
            contentType: newFileOriginal.type,
            key: 'elevation',
            type: 'raw',
            elevationId: elevationDetails.elevation.id,
            size: item.file.size
          }
          // let originalSigned = await uploadElevationImage(newFileOriginal.name, newFileOriginal.type)
          let originalSigned = await uploadElevationImage(dataThumbnailOrg)
          await fetch(originalSigned.signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': newFileOriginal.type
            },
            body: newFileOriginal
          })
          item.urlOriginal = originalSigned.signedUrl.split('?')[0]
          item.fileName = fileName
          delete item.new
          delete item.file
        }
      }

      if (updatedListManualImages.length !== 0) {
        for (const item of updatedListManualImages) {
          if (item.new) {
            const nanoiId = nanoid()
            if (item.camera) {
              const newFile = dataURLtoFile(item.file, nanoiId)
              item.file = newFile
            }
            // compress image
            const newFileName = changeFileName(nanoiId + item.file.name, 'jpg')
            const fileName = decodeURIComponent(item.file.name)
            const blob = item.file.slice(0, item.file.size, 'image/jpeg')
            const newFile = new File([blob], newFileName, { type: 'image/jpeg' })
            const dataThumbnail = {
              filename: newFile.name,
              contentType: newFile.type,
              key: 'elevation',
              type: 'compressed',
              elevationId: elevationDetails.elevation.id,
              size: item.file.size
            }
            // const { signedUrl } = await uploadElevationImage(newFile.name, newFile.type)
            const { signedUrl } = await uploadElevationImage(dataThumbnail)
            // save image to s3
            let maskingUrl = {}
            const compressedFile = await imageCompression(newFile, options)
            // save image to s3
            await fetch(signedUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': newFile.type
              },
              body: compressedFile
            })
            maskingUrl = await aiAutoMasking({
              elevation_id: elevationDetails.elevation.id,
              type: 'manual',
              url: signedUrl.split('?')[0]
            })

            reset()
            numImages += 1
            setCount(numImages)

            item.url = maskingUrl.newUrl
            // item.url = signedUrl.split('?')[0]

            //original image
            const newFileNameOriginal = changeFileName('original_' + nanoiId + item.file.name, 'jpg')
            const newFileOriginal = new File([blob], newFileNameOriginal, { type: 'image/jpeg' })
            const dataThumbnailOrg = {
              filename: newFileOriginal.name,
              contentType: newFileOriginal.type,
              key: 'elevation',
              type: 'raw',
              elevationId: elevationDetails.elevation.id,
              size: item.file.size
            }
            // let originalSigned = await uploadElevationImage(newFileOriginal.name, newFileOriginal.type)
            let originalSigned = await uploadElevationImage(dataThumbnailOrg)
            await fetch(originalSigned.signedUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': newFileOriginal.type
              },
              body: newFileOriginal
            })
            item.urlOriginal = originalSigned.signedUrl.split('?')[0]
            item.fileName = fileName
            let metadata = await new Promise((resolve) =>
              EXIF.getData(item.file, function () {
                const exifData = {
                  DateTimeOriginal: EXIF.getTag(this, 'DateTimeOriginal'),
                  GPSLatitudeRef: EXIF.getTag(this, 'GPSLatitudeRef'),
                  GPSLatitude: EXIF.getTag(this, 'GPSLatitude'),
                  GPSLongitudeRef: EXIF.getTag(this, 'GPSLongitudeRef'),
                  GPSLongitude: EXIF.getTag(this, 'GPSLongitude'),
                  GPSAltitude: EXIF.getTag(this, 'GPSAltitude'),
                  GPSAltitudeRef: EXIF.getTag(this, 'GPSAltitudeRef')
                }
                resolve(exifData)
              })
            )
            if (!(JSON.stringify(metadata) === '{}')) {
              item.metadata = metadata
            }
            delete item.new
            delete item.file
            delete item?.camera
          }
        }
      } else {
        for (let i = 0; i < manualRow; i++) {
          for (let k = 0; k < columns; k++) {
            updatedListManualImages.push({
              url: process.env.REACT_APP_AWS_BLANK_IMAGE,
              urlOriginal: process.env.REACT_APP_AWS_BLANK_IMAGE
            })
          }
        }
      }

      const data_thumbnail_req = {
        thumbnailUrl: elevationDetails.elevation.data_thumbnail.thumbnailUrl,
        closeRange: elevationDetails.elevation.data_thumbnail.closeRange
      }

      const newListImageDrone = updatedListImgs
        .map(({ row, col, file, ...rest }) => ({ ...rest }))
        .filter((i) => i.order)

      const newListThermalImage = updatedListThermalImgs
        .map(({ row, col, file, ...rest }) => ({ ...rest }))
        .filter((i) => i.order)

      const dataReq = {
        columns: columns,
        rows: rows,
        elevation_id: elevationDetails.elevation.id,
        building_id: elevationDetails.elevation.building_id,
        images: newListImageDrone,
        data_thumbnail: data_thumbnail_req,
        manual_rows: manualRow,
        manual_images: [...updatedListManualImages],
        thermal_images: newListThermalImage
      }
      setCount(0)
      dispatch(editElevation(dataReq))
      setIsShowProcess(false)
      setSavedAnnotation([])
      setLockSaveBtn(true)
    } catch (error) {
      toast.error('Saving error')
      setLockSaveBtn(true)
    }
  }

  const handleClickOnMap = (imgInfo) => {
    if (!imgInfo?.url?.includes('base64')) {
      if (role !== 'Image' && role !== 'ClientImage' && role !== 'ClientGuest') {
        if (imgInfo?.fromUrl) {
          setPopUpImageCloseRange('up')
        } else {
          setPopUpManualImage('up')
        }
      }
      setSelected(imgInfo)
      setImageItem(imgInfo)
    } else {
      toast.error('Please click save button first')
    }
  }

  const handleDeleteElevation = async (e) => {
    dispatch(deleteElevation(elevationDetails.elevation.id))
  }

  const handleDeleteCloseRangeElevation = async (id, defectId) => {
    const dataReq = { id, elevation_id: elevationDetails.elevation.id, defectId }
    dispatch(deleteCloseRangeElevation(dataReq))
  }

  const handleMaskingElevation = async (e) => {
    setSubmitting(true)
    dispatch(maskingElevation(parseInt(elevationDetails.elevation.id)))
  }

  //  fake drone canvas
  const fakeDroneCanvas = async () => {
    for (let index = 0; index < listImgs.length; index++) {
      await createCanvasItemDrone(listImgs[index])
    }
  }

  // save drone annotate
  const createCanvasItemDrone = async (item) => {
    if (buildingDetails?.building?.status === 'Completed') {
      toast.error('Cannot edit annotation to image in Completed building', {
        theme: 'dark',
        autoClose: 2000
      })
    } else {
      setSubmitting(true)
      try {
        let newReview = []
        for (let i = 0; i < item.review.length; i++) {
          const annotate = item.review[i]
          let canvasUrl = await handleCreateCloseupImage(
            annotate,
            item?.urlOriginal || item.url,
            elevationDetails,
            reset
          )
          newReview.push({
            ...annotate,
            canvasUrl: canvasUrl
          })
        }

        const dataReq = {
          elevation_id: elevationDetails.elevation.id,
          order: item.order,
          url: item.url,
          review: newReview
        }

        dispatch(createAnnotationDroneElevation(dataReq))
      } catch (error) {
        toast.error('Saving error')
      }
    }
  }
  // save drone annotate
  const handleSaveNewAnnotate = async (review) => {
    if (buildingDetails?.building?.status === 'Completed') {
      toast.error('Cannot edit annotation to image in Completed building', {
        theme: 'dark',
        autoClose: 2000
      })
    } else {
      setSubmitting(true)
      if (review) {
        let newListAnnotates = [...listAnnotates]
        let findIndex = newListAnnotates.map((i) => i.url).indexOf(selected?.url)
        const newReview = []
        for (let i = 0; i < review.length; i++) {
          const annotate = review[i]
          if (findIndex === -1) {
            let url = await handleCreateCloseupImage(annotate, selected?.url, elevationDetails, reset)
            newReview.push({
              ...annotate,
              x_yolo: annotate.x + annotate.w / 2,
              y_yolo: annotate.y - annotate.h / 2,
              canvasUrl: url
            })
            continue
          } else if (!listAnnotates[findIndex].review[i]) {
            let url = await handleCreateCloseupImage(annotate, selected?.url, elevationDetails, reset)
            newReview.push({
              ...annotate,
              x_yolo: annotate.x + annotate.w / 2,
              y_yolo: annotate.y - annotate.h / 2,
              canvasUrl: url
            })
            continue
          } else if (!deepEqual(annotate, listAnnotates[findIndex].review[i], ['x', 'y', 'w', 'h', 'color'])) {
            let url = await handleCreateCloseupImage(annotate, selected?.url, elevationDetails, reset)
            newReview.push({
              ...annotate,
              x_yolo: annotate.x + annotate.w / 2,
              y_yolo: annotate.y - annotate.h / 2,
              canvasUrl: url
            })
            continue
          } else if (!annotate.canvasUrl) {
            let url = await handleCreateCloseupImage(annotate, selected?.url, elevationDetails, reset)
            newReview.push({
              ...annotate,
              x_yolo: annotate.x + annotate.w / 2,
              y_yolo: annotate.y - annotate.h / 2,
              canvasUrl: url
            })
            continue
          }
          newReview.push({
            ...annotate,
            x_yolo: annotate.x + annotate.w / 2,
            y_yolo: annotate.y - annotate.h / 2,
            canvasUrl: newListAnnotates[findIndex].review[i].canvasUrl
          })
        }

        if (findIndex >= 0) {
          const anno = {
            url: selected?.url,
            review: newReview
          }
          newListAnnotates.splice(findIndex, 1, anno)
          setListAnnotatesDrone(newListAnnotates)
        } else {
          const anno = {
            url: selected?.url,
            review: newReview
          }
          newListAnnotates.push(anno)
          setListAnnotatesDrone(newListAnnotates)
        }
        try {
          const dataReq = {
            elevation_id: elevationDetails.elevation.id,
            order: selected.order,
            url: selected?.url,
            review: newReview
          }
          dispatch(createAnnotationDroneElevation(dataReq))
        } catch (error) {
          toast.error('Saving error')
        }
      }
    }
  }

  const handleSaveNewAnnotateImageUser = async (review) => {
    if (buildingDetails?.building?.status === 'Completed') {
      toast.error('Cannot edit annotation to image in Completed building', {
        theme: 'dark',
        autoClose: 2000
      })
    } else {
      setSubmitting(true)
      if (review) {
        let newListAnnotates = [...listReviewManualImages].filter((l) => l.review)
        let findIndex = newListAnnotates.map((i) => i.url).indexOf(selected?.url)
        const newReview = []
        for (let i = 0; i < review.length; i++) {
          const annotate = review[i]
          if (findIndex === -1) {
            let url = await handleCreateCloseupImage(annotate, selected?.url, elevationDetails, reset)
            newReview.push({
              ...annotate,
              x_yolo: annotate.x + annotate.w / 2,
              y_yolo: annotate.y - annotate.h / 2,
              canvasUrl: url
            })
            continue
          } else if (!listReviewManualImages[findIndex].review[i]) {
            let url = await handleCreateCloseupImage(annotate, selected?.url, elevationDetails, reset)
            newReview.push({
              ...annotate,
              x_yolo: annotate.x + annotate.w / 2,
              y_yolo: annotate.y - annotate.h / 2,
              canvasUrl: url
            })
            continue
          } else if (!deepEqual(annotate, listReviewManualImages[findIndex].review[i], ['x', 'y', 'w', 'h', 'color'])) {
            let url = await handleCreateCloseupImage(annotate, selected?.url, elevationDetails, reset)
            newReview.push({
              ...annotate,
              x_yolo: annotate.x + annotate.w / 2,
              y_yolo: annotate.y - annotate.h / 2,
              canvasUrl: url
            })
            continue
          } else if (!annotate.canvasUrl) {
            let url = await handleCreateCloseupImage(annotate, selected?.url, elevationDetails, reset)
            newReview.push({
              ...annotate,
              x_yolo: annotate.x + annotate.w / 2,
              y_yolo: annotate.y - annotate.h / 2,
              canvasUrl: url
            })
            continue
          }
          newReview.push({
            ...annotate,
            canvasUrl: newListAnnotates[findIndex].review[i].canvasUrl
          })
        }

        if (findIndex >= 0) {
          const anno = {
            url: selected?.url,
            review: newReview
          }
          newListAnnotates.splice(findIndex, 1, anno)
          setListReviewManualImages(newListAnnotates)
        } else {
          const anno = {
            url: selected?.url,
            review: newReview
          }
          newListAnnotates.push(anno)
          setListReviewManualImages(newListAnnotates)
        }

        try {
          const dataReq = {
            elevation_id: elevationDetails.elevation.id,
            url: selected?.url,
            review: newReview
          }
          dispatch(createAnnotationUserElevation(dataReq))
        } catch (error) {
          toast.error('Saving error')
        }
      }
    }
  }

  const handleAddNewImageUser = (review) => {
    setReviewOnThumbnail([...review])
    setPopupAddImageType(true)
  }

  const handleClickUploadImage = () => {
    imageUploadRef.current.click()
  }

  const closePopupAddCloseRangeByThubmnail = () => {
    setPopUpCloseRangeThumbnail('down')
    setReviewOnThumbnail()
    setSavedAnnotation([])
    setCloseRangeDefectId()
    setPopupAddImageType(false)
  }

  const updateReview = () => {
    let review = {
      ...reviewOnThumbnail[0],
      floor: floors,
      gridline: gridline
    }
    if (savedAnnotation?.length === 0) {
      setSavedAnnotation([review])
    } else {
      const listReview = [...savedAnnotation, review]
      setSavedAnnotation(listReview)
    }
  }

  const handleCheckDoneAnnotate = (e, positionCheck) => {
    let newPositions = [...position]
    const findIndex = {
      row: 0,
      col: 0
    }
    position.map((arrRow, index) => {
      arrRow.map((col, idx) => {
        if (col.order === positionCheck) {
          findIndex.row = index
          findIndex.col = idx
        }
      })
    })
    const newPosition = {
      order: positionCheck,
      url: selected?.url,
      check: e
    }
    newPositions[findIndex.row].splice(findIndex.col, 1, newPosition)
    setPosition(newPositions)

    let newImgs = [...listImgs]
    const findId = listImgs.map((i) => i.order).indexOf(positionCheck)
    newImgs.splice(findId, 1, newPosition)
    setListImgs(newImgs)

    try {
      const dataReq = {
        elevation_id: elevationDetails.elevation.id,
        url: selected?.url,
        check: e
      }
      dispatch(updateCheckedDroneAnnotationElevation(dataReq))
    } catch (error) {
      toast.error('Checking error')
    }
  }

  const handleChangeStatusCloseRangeDrone = (data) => {
    try {
      const dataReq = {
        elevation_id: elevationDetails.elevation.id,
        url: data.url,
        closeRange: !data?.closeRangeStatus,
        order: data.order
      }
      dispatch(updateStatusCloseRangeDrone(dataReq))
    } catch (error) {
      toast.error('Checking error')
    }
  }

  const handleChangeStatusCloseRangeManual = (data) => {
    try {
      const dataReq = {
        elevation_id: elevationDetails.elevation.id,
        url: data.url,
        closeRange: !data?.closeRangeStatus
      }
      dispatch(updateStatusCloseRangeManual(dataReq))
    } catch (error) {
      toast.error('Checking error')
    }
  }

  const handleSetAvatar = async (file) => {
    try {
      setSubmitting(true)
      const dataReq = {
        elevation_id: elevationDetails?.elevation?.id,
        name: elevationDetails?.elevation.name,
        file: file,
        avatarUrl: elevationDetails?.elevation?.data_thumbnail?.thumbnailUrl,
        upload: true,
        type: 'thumbnailUrl'
      }
      dispatch(updateElevationThumbnail(dataReq))
    } catch (err) {
      toast.error('Error when updating elevations')
    }
  }

  const handleReplaceUpload = (data) => {
    if (data.urlOrigin || data.url || data.thermalUrlOrigin || data.thermalUrl) {
      setSelected(null)
      setPopUpPosition()
      setShowThermalImage(data)
    }
  }

  const handleChangColumnDeleteDrone = () => {
    const newImgs = [...listImgs]
    if (newImgs?.length === 1) {
      setListManualImages([])
      setManualRow(0)
    } else if (newImgs?.length <= columns && direction === 0) {
      var indexRa = columns - 1
      const newListManual = [...listManualImages]
      for (let i = 0; i < manualRow; i++) {
        newListManual.splice(indexRa, 1)
        indexRa += columns - 1
      }
      setListManualImages(newListManual)
    } else if (newImgs?.length <= columns && direction === 1) {
      var indexRb = 0
      const newListManual = [...listManualImages]
      for (let i = 0; i < manualRow; i++) {
        newListManual.splice(indexRb, 1)
        indexRb += columns - 1
      }
      setListManualImages(newListManual)
    } else if ((newImgs?.length % rows === 1 || listImgs.length === rows + 1) && direction === 2) {
      var indexR = columns - 1
      const newListManual = [...listManualImages]
      for (let i = 0; i < manualRow; i++) {
        newListManual.splice(indexR, 1)
        indexR += columns - 1
      }
      setListManualImages(newListManual)
    } else if ((newImgs?.length % rows === 1 || listImgs.length === rows + 1) && direction === 3) {
      var indexR3 = 0
      const newListManual = [...listManualImages]
      for (let i = 0; i < manualRow; i++) {
        newListManual.splice(indexR3, 1)
        indexR3 += columns - 1
      }
      setListManualImages(newListManual)
    }
  }

  const handleCloseModal = () => {
    setPopUpThumbnail('down')
    setReviewOnThumbnail([])
    setSavedAnnotation([])
  }

  const handleChangColumnManual = () => {
    const newImgs = [...listImgs]
    if (newImgs?.length % columns === 0 && direction === 2) {
      var indexR = columns
      const newListManual = [...listManualImages]
      for (let i = 0; i < manualRow; i++) {
        newListManual.splice(indexR, 0, {
          order: nanoid(),
          url: process.env.REACT_APP_AWS_BLANK_IMAGE,
          urlOrigin: process.env.REACT_APP_AWS_BLANK_IMAGE
        })
        indexR += columns + 1
      }
      setListManualImages(newListManual)
    } else if (newImgs?.length % columns === 0 && direction === 3) {
      var indexR3 = 0
      const newListManual = [...listManualImages]
      for (let i = 0; i < manualRow; i++) {
        newListManual.splice(indexR3, 0, {
          order: nanoid(),
          url: process.env.REACT_APP_AWS_BLANK_IMAGE,
          urlOrigin: process.env.REACT_APP_AWS_BLANK_IMAGE
        })
        indexR3 += columns + 1
      }
      setListManualImages(newListManual)
    }
  }

  const handleAddDummyImg = (positionAdd) => {
    const newImgs = [...listImgs]

    const findIndex = newImgs.map((i) => i.order).indexOf(positionAdd)
    const dummy = {
      order: positionAdd + 1,
      url: process.env.REACT_APP_AWS_BLANK_IMAGE
    }
    handleChangColumnManual()
    const updatedImgs = newImgs.map((img) => (img.order >= positionAdd + 1 ? { ...img, order: img.order + 1 } : img))
    updatedImgs.splice(findIndex + 1, 0, dummy)

    if (listThermalImages.length > 0) {
      const newThermalImages = [...listThermalImages]
      const updatedThermalImages = newThermalImages.map((img) =>
        img.order >= positionAdd + 1 ? { ...img, order: img.order + 1 } : img
      )
      updatedThermalImages.splice(findIndex + 1, 0, dummy)
      setListThermalImages(updatedThermalImages)
    }

    setListImgs(updatedImgs)
    setLockSaveBtn(false)
  }

  const handleAddNewImage = (newItem, positionAdd) => {
    const newImgs = [...listImgs]

    const findIndex = newImgs.map((i) => i.order).indexOf(positionAdd)
    const newImage = {
      order: positionAdd + 1,
      url: newItem.data_url,
      file: newItem.file,
      new: true
    }
    handleChangColumnManual()
    const updatedImgs = newImgs.map((img) => (img.order >= positionAdd + 1 ? { ...img, order: img.order + 1 } : img))
    updatedImgs.splice(findIndex + 1, 0, newImage)
    setListImgs(updatedImgs)
    setLockSaveBtn(false)
    if (listThermalImages.length > 0) {
      const newThermalImgs = [...listThermalImages]
      let input = document.createElement('input')
      input.addEventListener('cancel', (evt) => {
        let newThermalImage = {
          order: positionAdd + 1,
          url: process.env.REACT_APP_AWS_BLANK_IMAGE
        }
        let updatedThermalImgs = newThermalImgs.map((img) =>
          img.order >= positionAdd + 1 ? { ...img, order: img.order + 1 } : img
        )
        updatedThermalImgs.splice(findIndex + 1, 0, newThermalImage)
        setListThermalImages(updatedThermalImgs)
      })
      input.type = 'file'
      input.accept = '.png, .jpg, .jpeg'
      input.onchange = async (event) => {
        let file = event.target.files[0]
        let url = await convertFileToBase64(file)
        let newThermalImage = {
          order: positionAdd + 1,
          url: url,
          file: file,
          new: true
        }
        let updatedThermalImgs = newThermalImgs.map((img) =>
          img.order >= positionAdd + 1 ? { ...img, order: img.order + 1 } : img
        )
        updatedThermalImgs.splice(findIndex + 1, 0, newThermalImage)
        setListThermalImages(updatedThermalImgs)
      }
      document.body.appendChild(input)
      input.click()
      document.body.removeChild(input)
    }
  }

  const handleReplaceImage = (newItem, positionAdd) => {
    let newImgs = [...listImgs]

    const findIndex = newImgs.map((i) => i.order).indexOf(positionAdd)
    const newImage = {
      order: positionAdd,
      url: newItem.data_url,
      file: newItem.file,
      new: true
    }
    newImgs.splice(findIndex, 1, newImage)
    setListImgs(newImgs)
    setLockSaveBtn(false)
  }

  const handleDeleteImage = (positionRemove) => {
    let newImgs = [...listImgs]
    handleChangColumnDeleteDrone()
    const findIndex = newImgs.findIndex((i) => i.order === positionRemove)
    const updatedImgs = newImgs.map((img) => (img.order >= positionRemove + 1 ? { ...img, order: img.order - 1 } : img))
    updatedImgs.splice(findIndex, 1)
    setListImgs(updatedImgs)
    setLockSaveBtn(false)

    if (listThermalImages.length > 0) {
      let newThermalImgs = [...listThermalImages]
      const updatedThermalImgs = newThermalImgs.map((img) =>
        img.order >= positionRemove + 1 ? { ...img, order: img.order - 1 } : img
      )
      updatedThermalImgs.splice(findIndex, 1)
      setListThermalImages(updatedThermalImgs)
    }
  }
  const handleCompositeImage = async () => {
    setExportLoading(true)

    if (elevationDetails?.elevation.composite_drone) {
      fetch(elevationDetails.elevation.composite_drone)
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]))
            const link = document.createElement('a')
            link.href = url
            link.download = `${elevationDetails?.elevation?.name ? elevationDetails.elevation.name : 'filename'}.png`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        })
        .catch((err) => {})
    }
    if (elevationDetails?.elevation.composite_manual) {
      fetch(elevationDetails.elevation.composite_manual)
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]))
            const link = document.createElement('a')
            link.href = url
            link.download = `${
              elevationDetails?.elevation?.name ? elevationDetails.elevation.name + '-manual' : 'filename-manual'
            }.png`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        })
        .catch((err) => {})
    }
    setExportLoading(false)
  }

  const handleReplaceManualImage = (newItem, positionAdd) => {
    let newManualImgs = [...listManualImages]
    const findIndex = newManualImgs.map((i) => i.id).indexOf(positionAdd)
    const newImage = {
      id: positionAdd,
      url: newItem[0].data_url,
      file: newItem[0].file,
      new: true
    }

    newManualImgs.splice(findIndex, 1, newImage)
    setListManualImages(newManualImgs)
    setLockSaveBtn(false)
  }
  const onReplacemanualPhotoByCamera = (newItem, positionAdd) => {
    let newManualImgs = [...listManualImages]
    const findIndex = newManualImgs.map((i) => i.id).indexOf(positionAdd.id)
    const newImage = {
      order: positionAdd.order,
      url: newItem,
      file: newItem,
      new: true,
      camera: true
    }

    newManualImgs.splice(findIndex, 1, newImage)
    setListManualImages(newManualImgs)
    setLockSaveBtn(false)
  }

  const handleDeleteManualImage = (positionRemove) => {
    const newImgs = [...listManualImages]
    const findIndex = newImgs.map((i) => i.id).indexOf(positionRemove)
    const newItem = {
      id: positionRemove,
      url: process.env.REACT_APP_AWS_BLANK_IMAGE,
      urlOriginal: process.env.REACT_APP_AWS_BLANK_IMAGE
    }
    newImgs.splice(findIndex, 1, newItem)
    setListManualImages(newImgs)
    setLockSaveBtn(false)
  }

  const handleSelectItem = (item) => {
    if (!item?.url?.includes('base64')) {
      if (item.url !== process.env.REACT_APP_AWS_BLANK_IMAGE) {
        setSelected(item)
        setImageItem(item)
        const annotateItem = listAnnotates?.filter((anno) => {
          return anno.url === item.url
        })
        setAnnotate(annotateItem[0]?.review || [])
        if (role !== 'ClientGuest') {
          setPopUpPosition('up')
        }
      }
    } else {
      toast.error('Please click save button first')
    }
  }

  const onExit = () => {
    setSelected(null)
    setPopUpPosition()
  }

  const handleNextItem = () => {
    const listNewWithoutBlank = listImgs.filter((i) => i.url !== process.env.REACT_APP_AWS_BLANK_IMAGE)
    const findIndex = listNewWithoutBlank.map((i) => i.order).indexOf(selected.order)
    setAnnotate([])
    listAnnotates?.forEach((anno) => {
      if (listNewWithoutBlank[findIndex + 1].url === anno.url) {
        setAnnotate(anno.review)
      }
    })
    setSelected(listNewWithoutBlank[findIndex + 1])
    setImageItem(listNewWithoutBlank[findIndex + 1])
  }

  const handlePreviousItem = () => {
    const listNewWithoutBlank = listImgs.filter((i) => i.url !== process.env.REACT_APP_AWS_BLANK_IMAGE)

    const findIndex = listNewWithoutBlank.map((i) => i.order).indexOf(selected.order)
    setAnnotate([])

    listAnnotates?.forEach((anno) => {
      if (listNewWithoutBlank[findIndex - 1]?.url === anno.url) {
        setAnnotate(anno.review)
      }
    })
    setSelected(listNewWithoutBlank[findIndex - 1])
    setImageItem(listNewWithoutBlank[findIndex - 1])
  }

  const handleEditColumn = (change) => {
    setColumns((prevCol) => (change ? prevCol + 1 : prevCol - 1))
  }

  const handleUploadDroneImage = () => {
    const path = `/elevation/create/drone/${id}`
    navigate(path, {
      state: {
        data_thumbnail: elevationDetails.elevation.data_thumbnail,
        elevation_name: elevationDetails.elevation.name,
        buildingDetails: buildingDetails?.building
      }
    })
  }

  const handleUploadThermalImage = () => {
    if (listImgs.length === 0) {
      toast.error('Add drone image first', {
        theme: 'dark'
      })
      return
    }
    const path = `/elevation/create/thermal/${id}`
    navigate(path)
  }

  const renderDrawerReplaceImage = () => {
    return (
      <SmartDrawer
        visible={showThermalImage ? true : false}
        onClose={() => {
          setShowThermalImage()
        }}
        title=""
        show
        width={isImageThermal ? `calc(100% - 100px)` : `50%`}
        zIndex={showThermalImage ? 998 : 10}
      >
        {showThermalImage && (
          <div className="flex flex-col h-full gap-6">
            <div className="flex text-blue-button italic"></div>
            <div className="relative flex flex-row gap-4 w-full h-full  bg-transparent">
              <div
                className={`${isImageThermal ? 'w-1/2' : 'w-full'} relative flex flex-col  items-center bg-transparent`}
              >
                <h2 className="flex p-2 font-bold">Drone image</h2>
                <CustomReplaceImage
                  currentImage={[]}
                  type="drone"
                  onChangeUpload={(props) => handleReplaceNewImagePreview(props, showThermalImage.order)}
                  showWarning={filterReviewAnnotation.length > 0}
                  status={buildingDetails?.building?.status}
                  visualStatus={elevationDetails?.elevation?.switch_visual}
                >
                  <DrawCanvasOnImage
                    imageUrl={
                      listImgs.filter((img) => img.order === showThermalImage?.order)[0]?.url ||
                      listImgs.filter((img) => img.order === showThermalImage?.order)[0]?.urlOriginal
                    }
                    rectangles={listImgs.filter((img) => img.order === showThermalImage?.order)[0]?.review || []}
                  />
                </CustomReplaceImage>
              </div>
              {isImageThermal && (
                <>
                  <div className="border-r border-red h-full w-[1px]"></div>
                  <div className="relative flex flex-col items-center w-1/2  bg-transparent ">
                    <h3 className="flex p-2 font-bold">Thermal image</h3>

                    <CustomReplaceImage
                      currentImage={[]}
                      type="thermal"
                      onChangeUpload={(props) => handleReplaceNewThermalImage(props, showThermalImage.order)}
                      status={buildingDetails?.building?.status}
                      visualStatus={elevationDetails?.elevation?.switch_visual}
                    >
                      <img
                        className="flex w-full h-[480px] rounded shadow-avatar"
                        src={
                          listThermalImages.filter((thermal) => thermal.order === showThermalImage?.order)[0]
                            ?.urlOriginal ||
                          listThermalImages.filter((thermal) => thermal.order === showThermalImage?.order)[0]?.url
                        }
                        alt="thermal"
                      />
                    </CustomReplaceImage>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </SmartDrawer>
    )
  }

  const handleCreateCloseRangeImage = async (types) => {
    setSubmitting(true)

    let closeRangeItem = ''

    if (types !== 3) {
      closeRangeItem = elevationDetails?.closeRangeImages?.find((img) => img.defectId === closeRangeDefectId)
    } else {
      closeRangeItem = elevationDetails?.closeRangeImages?.find((img) => img.defectId === savedAnnotation[0]?.id)
    }

    if (closeRangeItem) {
      await handleEditCloseRange(closeRangeImage?.review, closeRangeItem)
      return
    }

    let closeRange = closeRangeImage.image
    try {
      //step 1: add sharp for compress images
      const nanoiId = nanoid()
      const newFileName = changeFileName(nanoiId + closeRange.file.name, 'jpg')
      const fileName = decodeURIComponent(closeRange.file.name)
      const blob = closeRange.file.slice(0, closeRange.file.size, 'image/jpeg')
      const newFile = new File([blob], newFileName, { type: 'image/jpeg' })

      const dataThumbnail = {
        filename: newFile.name,
        contentType: newFile.type,
        key: 'elevation',
        type: 'other',
        elevationId: elevationDetails.elevation.id,
        size: closeRange.file.size
      }
      const { signedUrl } = await uploadElevationImage(dataThumbnail)
      // step 2: call apis upload
      // let maskingUrl = {}
      const compressedFile = await imageCompression(newFile, options)
      // save image to s3
      await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': newFile.type
        },
        body: compressedFile
      })
      // maskingUrl = await aiAutoMasking({
      //   elevation_id: elevationDetails.elevation.id,
      //   url: signedUrl.split('?')[0]
      // })

      closeRange.newUrl = signedUrl.split('?')[0]

      //original image
      const newFileNameOriginal = changeFileName('original_' + nanoiId + closeRange.file.name, 'jpg')

      const newFileOriginal = new File([blob], newFileNameOriginal, { type: 'image/jpeg' })
      const dataThumbnailOrg = {
        filename: newFileOriginal.name,
        contentType: newFileOriginal.type,
        key: 'elevation',
        type: 'raw',
        elevationId: elevationDetails.elevation.id,
        size: closeRange.file.size
      }
      let originalSigned = await uploadElevationImage(dataThumbnailOrg)
      await fetch(originalSigned.signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': newFileOriginal.type
        },
        body: newFileOriginal
      })
      closeRange.originalUrl = originalSigned.signedUrl.split('?')[0]
      closeRange.fileName = fileName

      let metadata = await new Promise((resolve) =>
        EXIF.getData(closeRange.file, function () {
          const exifData = {
            DateTimeOriginal: EXIF.getTag(this, 'DateTimeOriginal'),
            GPSLatitudeRef: EXIF.getTag(this, 'GPSLatitudeRef'),
            GPSLatitude: EXIF.getTag(this, 'GPSLatitude'),
            GPSLongitudeRef: EXIF.getTag(this, 'GPSLongitudeRef'),
            GPSLongitude: EXIF.getTag(this, 'GPSLongitude'),
            GPSAltitude: EXIF.getTag(this, 'GPSAltitude'),
            GPSAltitudeRef: EXIF.getTag(this, 'GPSAltitudeRef')
          }
          resolve(exifData)
        })
      )
      if (!(JSON.stringify(metadata) === '{}')) {
        closeRange.metadata = metadata
      }

      const url = await handleCreateCloseupImage(
        closeRangeImage.review[0],
        closeRange.originalUrl,
        elevationDetails,
        reset
      )
      const newCloseRangeItem = {
        ...closeRangeImage.review[0],
        canvasUrl: url
      }

      const newReview = [...closeRangeImage.review]
      newReview.splice(0, 1, newCloseRangeItem)

      if (types === 3) {
        const dataReq = {
          elevation_id: elevationDetails.elevation.id,
          building_id: elevationDetails.elevation.building_id,
          closeRange: savedAnnotation,
          url: closeRange.newUrl,
          fileName: closeRange.fileName,
          originalUrl: closeRange.originalUrl,
          defectId: savedAnnotation[0].id,
          review: newReview,
          metadata: closeRange.metadata,
          floor: floors,
          gridline: gridline
        }
        dispatch(createCloseRangeInThumbnail(dataReq))
      } else {
        const dataReq = {
          elevation_id: elevationDetails.elevation.id,
          url: closeRange.newUrl,
          fileName: closeRange.fileName,
          originalUrl: closeRange.originalUrl,
          fromUrl: selected?.url,
          defectId: closeRangeDefectId,
          type: types === 1 ? 'Drone' : 'Manual',
          review: newReview,
          metadata: closeRange.metadata
        }
        dispatch(createAnnotationCloseRangeElevation(dataReq))
      }
    } catch (error) {
      toast.error('Saving error')
    }
  }
  const handleEditCloseRange = async (review, dataRange) => {
    if (review.length > 1) {
      toast.error('Please add one defect for one close-range image', {
        theme: 'dark'
      })
    } else if (review.length === 0) {
      toast.error('Please add a defect', {
        theme: 'dark'
      })
    } else {
      setSubmitting(true)
      try {
        const newReview = [...review]

        // !deepEqual(annotate, listAnnotates[findIndex].review[i], ['x', 'y', 'w', 'h', 'color']))
        if (!deepEqual(review[0], dataRange?.review[0], ['x', 'y', 'w', 'h', 'color'])) {
          const url = await handleCreateCloseupImage(review[0], dataRange.originalUrl, elevationDetails, reset)

          const newCloseRangeItem = {
            ...review[0],
            canvasUrl: url
          }
          newReview.splice(0, 1, newCloseRangeItem)
        }

        if (dataRange.type === 'Thumbnail') {
          const dataReq = {
            ...dataRange,
            elevation_id: elevationDetails.elevation.id,
            closeRange: elevationDetails.elevation.data_thumbnail.closeRange.filter(
              (item) => item.id === dataRange.defectId
            ),
            building_id: elevationDetails.elevation.building_id,
            review: newReview
          }
          dispatch(createCloseRangeInThumbnail(dataReq))
        } else {
          const dataReq = {
            ...dataRange,
            review: newReview
          }
          dispatch(createAnnotationCloseRangeElevation(dataReq))
        }
      } catch (error) {
        toast.error('Saving error')
      }
    }
  }
  useEffect(() => {
    if (elevationDetails && elevationDetails.elevation) {
      setData()
      if (!buildingDetails) {
        dispatch(getBuildingDetails(elevationDetails.elevation.building_id))
      }
    }
  }, [elevationDetails])

  useEffect(() => {
    dispatch(getElevationDetails(id))
  }, [])

  useEffect(() => {
    if (buildingDetails?.building?.company_id) {
      dispatch(getAllCompanyReason(buildingDetails?.building?.company_id))
      dispatch(getAllCompanyRepair(buildingDetails?.building?.company_id))
    }
  }, [dispatch, buildingDetails?.building?.company_id])

  useEffect(() => {
    const updateSizeThubnail = () => {
      setThumbnailH(thumbnailRef.current?.offsetHeight)
      setThumbnailW(thumbnailRef.current?.offsetWidth)
    }

    updateSizeThubnail()
    window.addEventListener('resize', updateSizeThubnail)
    return () => {
      window.removeEventListener('resize', updateSizeThubnail)
    }
  }, [])

  useEffect(() => {
    // componentDidMount events
    return () => {
      dispatch(resetElevation(elevationTypes.GET_ELEVATION_DETAILS))
      setMounted(false)
      closePopupAddCloseRangeByThubmnail()
      handleCloseModal()
      setCloseRangeImage(null)
    }
  }, [])

  useEffect(() => {
    if (prevElevationGetFetching && !loadingsElevation[elevationTypes.GET_ELEVATION_DETAILS]) {
      if (!errorsElevation[elevationTypes.GET_ELEVATION_DETAILS]) {
        setRenderingProcess((prev) => ({ ...prev, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationGetFetching])

  // update fetching
  useEffect(() => {
    if (prevElevationUpdateFetching && !loadingsElevation[elevationTypes.UPDATE_ELEVATION]) {
      if (!errorsElevation[elevationTypes.UPDATE_ELEVATION]) {
        toast.success('Elevation updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.UPDATE_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationUpdateFetching])

  useEffect(() => {
    if (prevElevationUpdateThumbnail && !loadingsElevation[elevationTypes.UPDATE_ELEVATION_THUMBNAIL]) {
      setSubmitting(false)
      if (!errorsElevation[elevationTypes.UPDATE_ELEVATION_THUMBNAIL]) {
        toast.success('Thumbnail updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.UPDATE_ELEVATION_THUMBNAIL], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationUpdateThumbnail])

  useEffect(() => {
    if (prevStatusCloseRangDroneFetching && !loadingsElevation[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE]) {
      if (!errorsElevation[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE]) {
        toast.success('Status updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevStatusCloseRangDroneFetching])

  useEffect(() => {
    if (prevStatusCloseRangManualFetching && !loadingsElevation[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL]) {
      if (!errorsElevation[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL]) {
        toast.success('Status updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevStatusCloseRangManualFetching])

  useEffect(() => {
    if (prevElevationDeleteFetching && !loadingsElevation[elevationTypes.DELETE_ELEVATION]) {
      if (!errorsElevation[elevationTypes.DELETE_ELEVATION]) {
        toast.success('Elevation deleted', {
          theme: 'dark',
          autoClose: 2000
        })

        navigate(`/inspections/${elevationDetails.elevation.building_id}`)
      } else {
        toast.error(errorsElevation[elevationTypes.DELETE_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationDeleteFetching, navigate])

  useEffect(() => {
    if (prevElevationCreateAnnotationDrone && !loadingsElevation[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION]) {
      setSubmitting(false)
      if (!errorsElevation[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION]) {
        //setSelected(null)

        toast.success('Annotation created', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationCreateAnnotationDrone])

  useEffect(() => {
    if (prevElevationCreateAnnotationUser && !loadingsElevation[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION]) {
      setSubmitting(false)
      if (!errorsElevation[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION]) {
        toast.success('Annotation created', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationCreateAnnotationUser])

  useEffect(() => {
    if (
      prevElevationCreateAnnotationCloseRange &&
      !loadingsElevation[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION]
    ) {
      setSubmitting(false)
      if (!errorsElevation[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION]) {
        // setSelected(null)
        // setPopUpImageCloseRange('down')
        toast.success('Close range image updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationCreateAnnotationCloseRange])

  useEffect(() => {
    if (
      prevElevationCreateCloseRangeInThumbnail &&
      !loadingsElevation[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL]
    ) {
      setSubmitting(false)
      if (!errorsElevation[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL]) {
        // setSelected(null)
        // setPopUpImageCloseRange('down')

        toast.success('Close range image updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationCreateCloseRangeInThumbnail])

  useEffect(() => {
    if (prevDeleteCloseRangeElevation && !loadingsElevation[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION]) {
      setSubmitting(false)
      if (!errorsElevation[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION]) {
        setSelected(null)
        toast.success('Close range image deleted', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevDeleteCloseRangeElevation])
  useEffect(() => {
    if (prevSetStatusElevation && !loadingsElevation[elevationTypes.SET_ELEVATION_STATUS]) {
      setSubmitting(false)
      if (!errorsElevation[elevationTypes.SET_ELEVATION_STATUS]) {
        toast.success(switcherMessage, {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.SET_ELEVATION_STATUS], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevSetStatusElevation])

  useEffect(() => {
    if (
      prevElevationUpdateCheckedAnnotationDrone &&
      !loadingsElevation[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION]
    ) {
      if (!errorsElevation[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION]) {
        setTimeout(() => {
          toast.success('Annotation updated', {
            theme: 'dark',
            autoClose: 2000
          })
        }, 500)
      } else {
        toast.error(errorsElevation[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationUpdateCheckedAnnotationDrone])

  useEffect(() => {
    if (prevAIMaskingElevation && !loadingsElevation[elevationTypes.AI_MASKING_ELEVATION]) {
      setSubmitting(false)
      if (!errorsElevation[elevationTypes.AI_MASKING_ELEVATION]) {
        toast.success('Complete masking', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsElevation[elevationTypes.AI_MASKING_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevAIMaskingElevation])

  useEffect(() => {
    if (listImgs && columns) {
      const newPos = []
      const newListArrRow = []
      let currentIndex = 1
      if (direction === 0 || direction === 1) {
        const numRow = Math.ceil(listImgs.length / columns)
        setRows(numRow)
        for (let i = 0; i < numRow; i++) {
          if (parseInt(direction) === 0) {
            if (i % 2 === 0) {
              newListArrRow.push('to-right')
            } else {
              newListArrRow.push('to-left')
            }
          } else {
            if (i % 2 !== 0) {
            } else {
            }
          }

          const newRows = []
          for (let j = 0; j < columns; j++) {
            const findIndex = listImgs.map((i) => i.order).indexOf(currentIndex)
            const findIndexThermal = listThermalImages?.map((i) => i.order).indexOf(currentIndex)
            const newEl = {
              ...listImgs[findIndex],
              thermalUrl: findIndexThermal >= 0 ? listThermalImages[findIndexThermal]?.url : '',
              thermalUrlOrigin: findIndexThermal >= 0 ? listThermalImages[findIndexThermal]?.urlOriginal : ''
            }
            if (parseInt(direction) === 0) {
              if (i % 2 === 0) {
                newRows.push({ ...newEl, row: i, col: j })
              } else {
                newRows.unshift({ ...newEl, row: i, col: columns - 1 - j })
              }
            } else {
              if (i % 2 !== 0) {
                newRows.push({ ...newEl, row: i, col: j })
              } else {
                newRows.unshift({ ...newEl, row: i, col: columns - 1 - j })
              }
            }

            currentIndex++
          }

          newPos.push(newRows)
        }
        setListArrowRow(newListArrRow)
        setPosition(newPos)
        if (listImgs.length < columns) {
          setColumns(listImgs.length)
        }
      } else {
        const numColumn = Math.ceil(listImgs.length / rows)
        setColumns(numColumn)
        for (let i = 0; i < numColumn; i++) {
          const newColumns = []
          for (let j = 0; j < rows; j++) {
            const findIndex = listImgs.map((i) => i.order).indexOf(currentIndex)
            const findIndexThermal = listThermalImages?.map((i) => i.order).indexOf(currentIndex)

            const newEl = {
              ...listImgs[findIndex],
              thermalUrl: findIndexThermal >= 0 ? listThermalImages[findIndexThermal]?.url : '',
              thermalUrlOrigin: findIndexThermal >= 0 ? listThermalImages[findIndexThermal]?.urlOriginal : ''
            }
            if (parseInt(direction) === 2) {
              if (i % 2 === 0) {
                newColumns.push({ ...newEl, row: j, col: i })
              } else {
                newColumns.unshift({ ...newEl, row: rows - 1 - j, col: i })
              }
            } else {
              if (i % 2 !== 0) {
                newColumns.push({ ...newEl, row: rows - 1 - j, col: numColumn - 1 - i })
              } else {
                newColumns.unshift({ ...newEl, row: j, col: numColumn - 1 - i })
              }
            }

            currentIndex++
          }

          newPos.push(newColumns)
        }
        if (parseInt(direction) === 3) {
          const posReverse = [...newPos].reverse()
          setPosition(posReverse)
        } else {
          setPosition(newPos)
        }
        if (listImgs.length < rows) {
          setRows(listImgs.length)
        }
      }
    }
  }, [listImgs, listThermalImages, direction])

  useEffect(() => {
    let index = 0
    let position = []

    for (let j = 0; j < manualRow; j++) {
      let pos = []
      for (let i = 0; i < columns; i++) {
        let item = { ...listManualImages[index], row: j + 1, column: i + 1 }
        pos.push(item)
        index++
      }

      position.push(pos)
    }
    setManualPosition(position)
  }, [listManualImages])

  useEffect(() => {
    const createCLoseRange = async () => {
      if (closeRangeImage) {
        await handleCreateCloseRangeImage(openTab)
      } else return
    }
    createCLoseRange()
  }, [closeRangeImage])

  useEffect(() => {
    const saveNew = async () => {
      if (savedAnnotation?.length > 0) {
        if (savedAnnotation[0].type === 'point') {
          setPopUpCloseRangeThumbnail('up')
        } else {
          await handleSaveNewImages()
        }
      } else return
    }
    saveNew()
  }, [savedAnnotation])

  const renderDrawer = () => {
    return (
      <SmartDrawer
        visible={imageCloseRangeOnDrawer?.show}
        onClose={() => setImageCloseRangeOnDrawer({})}
        title="Close range image"
        width={500}
        zIndex={showCloseRangeOndrawer ? 10 : 1000}
        children={
          <>
            <div
              className="relative flex flex-row items-center justify-center h-[480px] pr-1 bg-transparent cursor-pointer w-[452px]"
              onClick={() => setShowCloseRangeOndrawer(true)}
            >
              <div className="relative flex flex-row items-center justify-center h-[480px] pr-1 bg-transparent cursor-pointer w-[452px]">
                <img
                  className="w-full h-full transition duration-500 transform rounded"
                  src={
                    elevationDetails?.closeRangeImages?.find(
                      (img) => img.id === imageCloseRangeOnDrawer?.region?.closeRangeImageId
                    )?.url
                  }
                  alt="close range"
                />
              </div>
              {elevationDetails?.closeRangeImages?.find(
                (img) => img.id === imageCloseRangeOnDrawer?.region?.closeRangeImageId
              )?.review &&
                elevationDetails?.closeRangeImages
                  .find((img) => img.id === imageCloseRangeOnDrawer?.region?.closeRangeImageId)
                  ?.review.map((review, idx) => {
                    if (review.cls === 'Unsafe') {
                      colorsDrawer[0].count += 1
                    } else if (review.cls === 'Require Repair') {
                      colorsDrawer[1].count++
                      if (review.export === true) {
                        colorsDrawer[1].include++
                      }
                    } else if (review.cls === 'Safe') {
                      colorsDrawer[2].count++
                      if (review.export === true) {
                        colorsDrawer[2].include++
                      }
                    } else if (review.cls === 'False Positive') {
                      colorsDrawer[3].count++
                    }
                    var l = 0

                    return (
                      <div key={idx}>
                        {colorsDrawer &&
                          colorsDrawer.map((item, i) => {
                            if (item.count !== 0) {
                              var left = 4 + 36 * l
                              l++
                              return (
                                <div
                                  key={i}
                                  className="absolute flex items-center justify-center w-12 h-12 bottom-2 left-4"
                                  style={{
                                    backgroundColor: `${item.color}`,
                                    left: `${left}px`
                                  }}
                                >
                                  {item.include ? (
                                    <span style={{ fontSize: '12px' }}>
                                      <sup>{item.include}</sup>&frasl;<sub>{item.count}</sub>
                                    </span>
                                  ) : (
                                    `${item.count}`
                                  )}
                                </div>
                              )
                            }
                          })}
                      </div>
                    )
                  })}
            </div>
          </>
        }
      />
    )
  }

  const renderSkeleton = () => {
    return (
      <div className="w-full mt-4">
        <div className="flex flex-row items-center justify-between">
          <div
            className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90
            bg-clip-padding flex flex-col"
            style={{ maxWidth: '90vw' }}
          >
            <div className="flex flex-row text-primary  font-bold border-b">
              <div className="w-1/3 border-r">
                <div className="flex flex-col px-4 py-4 w-full gap-4">
                  <SkeletonViewer height={30} width={360} />
                  <SkeletonViewer height={30} width={360} />
                  <SkeletonViewer height={40} width={360} />
                  <SkeletonViewer height={52} width={360} />
                  <SkeletonViewer height={52} width={360} />
                </div>
              </div>

              <div className="w-1/3 border-r">
                <div className="flex flex-col w-full px-6 py-4">
                  <SkeletonViewer count={4} height={18} width={320} />
                  <SkeletonViewer className="my-2" borderRadius={8} height={56} width={160} />
                  <div className="flex my-2 gap-2">
                    <SkeletonViewer className="my-2" borderRadius={8} height={46} width={98} />
                    <SkeletonViewer className="my-2" borderRadius={8} height={46} width={98} />
                    <SkeletonViewer className="my-2" borderRadius={8} height={46} width={98} />
                  </div>
                </div>
              </div>

              <div className="flex w-1/3 p-2">
                <div className="m-auto ">
                  <SkeletonViewer width={340} height={240} />
                </div>
                <div>
                  <SkeletonViewer borderRadius={90} width={36} height={36} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* tab list */}
        <ul className="flex mb-0 list-none flex-wrap pt-4 flex-row" role="tablist">
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <SkeletonViewer borderRadius={4} height={40} style={{ width: '100%' }} />
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <SkeletonViewer borderRadius={4} height={40} style={{ width: '100%' }} />
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <SkeletonViewer borderRadius={4} height={40} style={{ width: '100%' }} />
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <SkeletonViewer borderRadius={4} height={40} style={{ width: '100%' }} />
          </li>
        </ul>

        {/* tab window  */}
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="px-4 py-2 flex-auto">
            <div className="tab-content tab-space">
              <div
                className="flex flex-col px-6 my-4 bg-white border overflow-auto border-gray-300 rounded shadow-lg bg-opacity-90 bg-clip-padding"
                style={{ maxWidth: '90vw' }}
              >
                <ScrollContainer className="scroll-container" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                  <div className="flex gap-1">
                    {Array.from({ length: 7 }, function (v, k) {
                      return k
                    }).map((i) => (
                      <div key={i} className="flex flex-col gap-1">
                        {Array.from({ length: 3 }, function (f, g) {
                          return g
                        }).map((idx) => (
                          <div key={`${idx} + id`}>
                            <SkeletonViewer borderRadius={4} width={240} height={192} />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </ScrollContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderDrawerShowLog = () => {
    return (
      <SmartDrawer
        visible={logDrawer ? true : false}
        onClose={() => {
          setShowLogDrawer(null)
        }}
        title={logDrawer?.title || ''}
        width={1000}
        zIndex={1000}
        children={logDrawer && <ElevationImageLogList type={logDrawer.type} id={logDrawer.id} />}
      />
    )
  }

  const renderImageDronePopup = () => {
    return (
      <>
        {selected && popUpPosition === 'up' && ![...makeRoles([5, 9, 11])].includes(role) && (
          <div>
            <ImagePopUp
              popUpPosition={popUpPosition}
              check={selected?.check ? selected.check : false}
              hideSave={
                buildingDetails?.building?.status === 'Completed' || !!elevationDetails?.elevation.switch_visual
                  ? true
                  : false
              }
              position={position}
              selected={selected}
              status={buildingDetails?.building?.status}
              visualStatus={elevationDetails?.elevation?.switch_visual}
              listImgs={listImgs}
              lengthList={listImgs?.length}
              region={annotate}
              onPrevious={handlePreviousItem}
              onNext={handleNextItem}
              onReplaceUpload={handleReplaceUpload}
              onExit={onExit}
              onCreateAnnotate={handleSaveNewAnnotate}
              onCheckDoneAnnotation={handleCheckDoneAnnotate}
              setShowLogDrawer={setShowLogDrawer}
              handleNavigateParamsUrl={handleNavigateParamsUrl}
            />
          </div>
        )}
      </>
    )
  }
  const renderImageManualPopup = () => {
    return (
      <>
        {selected && selected.fileName && popUpManualImage === 'up' && ![...makeRoles([5, 9, 11])].includes(role) && (
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <ImageUserPopUp
              selected={selected}
              status={buildingDetails?.building?.status}
              visualStatus={elevationDetails?.elevation?.switch_visual}
              listManualImages={listManualImages}
              setListManualImages={setListManualImages}
              popUpManualImage={popUpManualImage}
              setPopUpManualImage={setPopUpManualImage}
              handleSaveNewAnnotateImageUser={handleSaveNewAnnotateImageUser}
              check={selected ? selected?.check : false}
              elevationDetails={elevationDetails}
              hideSave={
                buildingDetails?.building?.status === 'Completed' || !!elevationDetails?.elevation.switch_visual
                  ? true
                  : false
              }
              onExit={onExit}
              setShowLogDrawer={setShowLogDrawer}
            />
          </div>
        )}
      </>
    )
  }

  const renderImageCloseRangePopup = () => {
    return (
      <>
        {selected && popUpImageCloseRange === 'up' && ![...makeRoles([5, 9, 11])].includes(role) && (
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <ImageCloseRangePopUp
              selected={selected}
              hideSave={
                buildingDetails?.building?.status === 'Completed' || elevationDetails?.elevation.switch_close_range
                  ? true
                  : false
              }
              popUpImageCloseRange={popUpImageCloseRange}
              setPopUpImageCloseRange={setPopUpImageCloseRange}
              onExit={onExit}
              onEditCloseRange={handleEditCloseRange}
              setShowLogDrawer={setShowLogDrawer}
            />
          </div>
        )}
      </>
    )
  }
  const renderPopopThumbnail = () => {
    return (
      <>
        {popUpThumbnail === 'closeRange' && (
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Modal onClose={handleCloseModal}>
              <div className="fixed top-16 left-32 h-5/6 w-10/12">
                {thumbnail && (
                  <>
                    <ReactImageAnnotate
                      taskTitle="Add close range image"
                      key={thumbnail}
                      hidePrev={true}
                      hideNext={true}
                      RegionEditLabel={CustomRegionLabelOnceColor}
                      enabledTools={['select', 'create-point']}
                      images={[
                        {
                          src: thumbnail ? thumbnail : '',
                          name: '',
                          regions: reviewOnThumbnail
                        }
                      ]}
                      onExit={(x) => {
                        if (x?.images[0]?.regions?.length === 1) {
                          if (
                            x.images[0].regions[0].x <= 0 ||
                            x.images[0].regions[0].x >= 1 ||
                            x.images[0].regions[0].y <= 0 ||
                            x.images[0].regions[0].y >= 1
                          ) {
                            toast.error('Create defect inside image')
                          } else {
                            setPopUpThumbnail('down')
                            handleAddNewImageUser(x.images[0].regions)
                          }
                        } else if (x.images[0].regions.length > 1) {
                          toast.error('Create only one position')
                        } else {
                          toast.error('Create position on thumbnail first')
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </Modal>
          </div>
        )}
      </>
    )
  }
  const renderCloseRangeDrawer = () => {
    return (
      <>
        {showCloseRangeOndrawer && (
          <div>
            <Modal onClose={() => setShowCloseRangeOndrawer(false)}>
              <div className="fixed top-16 left-32 h-5/6 w-10/12">
                <ReactImageAnnotate
                  taskTitle="Edit close range image"
                  hideSave={
                    buildingDetails?.building?.status === 'Completed' || elevationDetails?.elevation.switch_close_range
                      ? true
                      : false
                  }
                  key={
                    elevationDetails?.closeRangeImages?.find(
                      (img) => img.id === imageCloseRangeOnDrawer?.region?.closeRangeImageId
                    )?.url
                  }
                  RegionEditLabel={CustomRegionLabel}
                  enabledTools={['select', 'create-box']}
                  regionClsList={[]}
                  images={[
                    {
                      src: elevationDetails?.closeRangeImages?.find(
                        (img) => img.id === imageCloseRangeOnDrawer?.region?.closeRangeImageId
                      )?.url,
                      name: '',
                      regions: elevationDetails?.closeRangeImages?.find(
                        (img) => img.id === imageCloseRangeOnDrawer?.region?.closeRangeImageId
                      )?.review
                    }
                  ]}
                  onExit={(x) => {
                    let checkOutSide = false
                    const closeRangeItem = elevationDetails?.closeRangeImages?.find(
                      (img) => img.id === imageCloseRangeOnDrawer?.region?.closeRangeImageId
                    )
                    x.images[0].regions.forEach((region) => {
                      if (region.x <= 0 || region.x >= 1 || region.y <= 0 || region.y >= 1) {
                        checkOutSide = true
                      }
                    })
                    if (checkOutSide) {
                      toast.error('Create defect inside image')
                    } else {
                      handleEditCloseRange(x.images[0].regions, closeRangeItem)
                    }
                  }}
                />
              </div>
            </Modal>
          </div>
        )}
      </>
    )
  }

  const renderPopupAddImageType = () => {
    return (
      <>
        {popupAddImageType && (
          <PopupAddImageType
            floors={floors}
            gridline={gridline}
            handleChangeFloor={handleChangeFloor}
            handleChangeGridline={handleChangeGridline}
            handleNumberFloor={handleNumberFloor}
            handleStringGridline={handleStringGridline}
            setPopupAddImageType={setPopupAddImageType}
            closePopupAddCloseRangeByThubmnail={closePopupAddCloseRangeByThubmnail}
            handleClickUploadImage={handleClickUploadImage}
            setCameraAddByThumbnail={setCameraAddByThumbnail}
          />
        )}
      </>
    )
  }
  return (
    <>
      {_mounted && (
        <>
          {submitting && <LoadingSpinner />}
          <Navigate
            options={optionNavigate}
            isBackButton={true}
            backToLink={`/inspections/${buildingDetails?.building.id}`}
          />
          <PaddingAuto>
            {renderingProcess.loading ? (
              <>{renderSkeleton()}</>
            ) : renderingProcess.error ? (
              <NotAvailable />
            ) : (
              <>
                {exportLoading && <LoadingSpinner />}
                <div className="w-full">
                  <div className="flex flex-row items-center justify-between">
                    <ElevationInfo
                      isLockReport={buildingDetails?.locked}
                      elevation={elevationDetails?.elevation}
                      switchVisual={elevationDetails?.elevation?.switch_visual}
                      fakeDroneCanvas={fakeDroneCanvas}
                      switchCloseRange={elevationDetails?.elevation?.switch_close_range}
                      buildingStatus={buildingDetails?.building?.status === 'Completed' ? true : false}
                      composeDrone={elevationDetails?.elevation?.composite_drone}
                      composeManual={elevationDetails?.elevation?.composite_manual}
                      name={elevationDetails?.elevation?.name}
                      listDefectCloseRangeThumbnail={elevationDetails?.elevation?.data_thumbnail?.closeRange}
                      listImageCloseRangeThumbnail={listImageCloseRangeThumbnail}
                      direction={elevationDetails?.elevation?.direction}
                      thumbnail={
                        thumbnail ? thumbnail : elevationDetails?.elevation?.data_thumbnail?.thumbnailUrl || ''
                      }
                      elevationRow={elevationDetails?.elevation?.manual_rows}
                      columns={columns}
                      rows={rows}
                      manualRow={manualRow}
                      setManualRow={setManualRow}
                      openTab={openTab}
                      isShowProcess={isShowProcess}
                      count={count}
                      totalNewItem={totalNewItem}
                      onEditColumns={handleEditColumn}
                      onSubmitSave={handleSaveNewImages}
                      onDelete={handleDeleteElevation}
                      onExportTxT={() => {
                        handleExportTxT()
                      }}
                      onExportTxTnImage={() => {
                        handleExportTxTnImage()
                      }}
                      handleClickOnMap={handleClickOnMap}
                      thumbnailH={thumbnailH}
                      thumbnailW={thumbnailW}
                      onLoad={onLoad}
                      thumbnailRef={thumbnailRef}
                      handleCompositeImage={handleCompositeImage}
                      lockSaveBtn={lockSaveBtn}
                      setLockSaveBtn={setLockSaveBtn}
                      onChangeElevationStatus={onChangeElevationStatus}
                      handleMaskingElevation={handleMaskingElevation}
                      handleSetAvatar={handleSetAvatar}
                    />
                  </div>

                  {/* tab list */}
                  <ul className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                    {elevationTab.map((tab) => {
                      return (
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center" key={tab.tabIndex}>
                          <div
                            className={
                              'text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                              (openTab === tab.tabIndex
                                ? 'text-white bg-blue-600 cursor-pointer '
                                : 'text-blue-600 bg-white cursor-pointer')
                            }
                            onClick={() => {
                              navigate(`/elevation/${id}/${tab.path}`)
                            }}
                          >
                            <div className="flex gap-1 items-center justify-center">
                              <>
                                {tab.name}
                                {tab.title && (
                                  <CommonTooltip title={tab.title} color={color.blue}>
                                    <svg
                                      className="inline flex-shrink-0 mr-3 w-5 h-5"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                  </CommonTooltip>
                                )}
                              </>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                  {/* tab window  */}
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                    <div className="px-4 py-4 flex-auto">
                      <div className="tab-content tab-space">
                        {openTab === 1 && (
                          <>
                            <div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
                              {(columns !== 0 && rows !== 0 && (
                                <ImageContainer
                                  listImgs={listImgs}
                                  status={buildingDetails?.building?.status}
                                  visualStatus={elevationDetails?.elevation.switch_visual}
                                  listArrowRow={listArrowRow}
                                  position={position}
                                  selected={selected}
                                  listCountColor={listCountColorAnnotates}
                                  direction={direction}
                                  handleThumbnail={handleThumbnail}
                                  onSelected={handleSelectItem}
                                  onAddDummy={handleAddDummyImg}
                                  onChangeUpload={handleUploadNewImagePreview}
                                  onReplaceUpload={handleReplaceUpload}
                                  onDelete={handleDeleteImage}
                                  onChangeStatusCloseRangeDrone={handleChangeStatusCloseRangeDrone}
                                />
                              )) ||
                                (lockSaveBtn && (
                                  <div className="justify-center flex">
                                    {[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) &&
                                      buildingDetails?.building?.status !== 'Completed' &&
                                      !elevationDetails?.elevation.switch_visual && (
                                        <button onClick={handleUploadDroneImage}>
                                          Upload Drone Images
                                          <EditOutlined className="text-3xl p-8" />
                                        </button>
                                      )}
                                  </div>
                                ))}
                            </div>
                          </>
                        )}
                        {openTab === 2 && (
                          <>
                            <div className={openTab === 2 ? 'block' : 'hidden'} id="link2">
                              <ImageUserList
                                isShow={true}
                                status={buildingDetails?.building?.status}
                                visualStatus={elevationDetails?.elevation.switch_visual}
                                selected={selected}
                                listManualImages={listManualImages}
                                columns={columns || 0}
                                imageUploadRef={imageUploadRef}
                                manualRow={manualRow}
                                handleReplaceManualImage={handleReplaceManualImage}
                                onDelete={handleDeleteManualImage}
                                handleSelectImageUser={handleClickOnMap}
                                onChangeStatusCloseRangeManual={handleChangeStatusCloseRangeManual}
                                handleThumbnail={handleThumbnail}
                                onReplacemanualPhotoByCamera={onReplacemanualPhotoByCamera}
                              />
                            </div>
                          </>
                        )}
                        {openTab === 3 && (
                          <>
                            <Collapse bordered={false} defaultActiveKey={['1', '2', '3']}>
                              <Panel
                                header={
                                  <div
                                    className="flex font-bold text-sm text-blue-700 rounded-lg dark:bg-blue-200 dark:text-blue-800"
                                    role="alert"
                                  >
                                    <svg
                                      className="inline flex-shrink-0 mr-3 w-5 h-5"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                    Close Range Defect in Drone Images.
                                  </div>
                                }
                                key="1"
                              >
                                <ImageCloseRangeList
                                  type="Drone"
                                  status={buildingDetails?.building?.status}
                                  closeRangeSwitchStatus={elevationDetails?.elevation.switch_close_range}
                                  onDelete={handleDeleteCloseRangeElevation}
                                  position={position}
                                  handleClickOnMap={handleClickOnMap}
                                  updateReview={updateReview}
                                  setGridline={setGridline}
                                  isShow={true}
                                  setFloors={setFloors}
                                  selected={selected}
                                  setListImageCloseRange={setListImageCloseRangeDrone}
                                  listImageCloseRange={listImageCloseRangeDrone}
                                  setPopUpThumbnail={setPopUpThumbnail}
                                  handleThumbnail={handleThumbnail}
                                  setReviewOnThumbnail={setReviewOnThumbnail}
                                  imageUploadRef={imageUploadRef}
                                />
                              </Panel>
                              <Panel
                                header={
                                  <div
                                    className="flex font-bold text-sm text-blue-700 rounded-lg dark:bg-blue-200 dark:text-blue-800"
                                    role="alert"
                                  >
                                    <svg
                                      className="inline flex-shrink-0 mr-3 w-5 h-5"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                    Close Range Defects in Manual Images.
                                  </div>
                                }
                                key="2"
                              >
                                <ImageCloseRangeList
                                  position={position}
                                  status={buildingDetails?.building?.status}
                                  closeRangeSwitchStatus={elevationDetails?.elevation.switch_close_range}
                                  manualPosition={manualPosition}
                                  onDelete={handleDeleteCloseRangeElevation}
                                  updateReview={updateReview}
                                  setGridline={setGridline}
                                  isShow={true}
                                  setFloors={setFloors}
                                  selected={selected}
                                  setListImageCloseRange={setListImageCloseRangeManual}
                                  listImageCloseRange={listImageCloseRangeManual}
                                  setPopUpThumbnail={setPopUpThumbnail}
                                  handleThumbnail={handleThumbnail}
                                  setReviewOnThumbnail={setReviewOnThumbnail}
                                  imageUploadRef={imageUploadRef}
                                  handleClickOnMap={handleClickOnMap}
                                  type="Manual"
                                />
                              </Panel>
                              <Panel
                                header={
                                  <div
                                    className="flex font-bold text-sm text-blue-700 rounded-lg dark:bg-blue-200 dark:text-blue-800"
                                    role="alert"
                                  >
                                    <svg
                                      className="inline flex-shrink-0 mr-3 w-5 h-5"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                    Close Range Defect in Elevation.
                                  </div>
                                }
                                key="3"
                              >
                                <ImageCloseRangeList
                                  position={position}
                                  status={buildingDetails?.building?.status}
                                  closeRangeSwitchStatus={elevationDetails?.elevation.switch_close_range}
                                  onDelete={handleDeleteCloseRangeElevation}
                                  updateReview={updateReview}
                                  setGridline={setGridline}
                                  isShow={true}
                                  setFloors={setFloors}
                                  setPopupAddImageType={setPopupAddImageType}
                                  cameraAddByThumbnail={cameraAddByThumbnail}
                                  setCameraAddByThumbnail={setCameraAddByThumbnail}
                                  selected={selected}
                                  setListImageCloseRange={setListImageCloseRangeThumbnail}
                                  listImageCloseRange={listImageCloseRangeThumbnail}
                                  setPopUpThumbnail={setPopUpThumbnail}
                                  handleThumbnail={handleThumbnail}
                                  setReviewOnThumbnail={setReviewOnThumbnail}
                                  imageUploadRef={imageUploadRef}
                                  popUpCloseRangeThumbnail={popUpCloseRangeThumbnail}
                                  setPopUpCloseRangeThumbnail={setPopUpCloseRangeThumbnail}
                                  type="Thumbnail"
                                  handleClickOnMap={handleClickOnMap}
                                  closePopupAddCloseRangeByThubmnail={closePopupAddCloseRangeByThubmnail}
                                  setSavedAnnotation={setSavedAnnotation}
                                />
                              </Panel>
                            </Collapse>
                          </>
                        )}
                        {openTab === 4 && (
                          <>
                            <div className={openTab === 4 ? 'block' : 'hidden'} id="link4">
                              {listThermalImages.length !== 0 ? (
                                <div className={openTab === 4 ? 'block' : 'hidden'} id="link4">
                                  <ImageThermalList position={position} direction={direction} />
                                </div>
                              ) : (
                                <div className="justify-center flex">
                                  {buildingDetails?.building?.status !== 'Completed' &&
                                    !elevationDetails?.elevation.switch_visual &&
                                    [...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) && (
                                      <button onClick={handleUploadThermalImage}>
                                        Upload Thermal Images
                                        <EditOutlined className="text-3xl p-8" />
                                      </button>
                                    )}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* render popup add image type*/}
                {renderPopupAddImageType()}
                {/* close range image on top drawer */}

                {/* render close range drawer */}
                {renderCloseRangeDrawer()}
                {/* drone Pop up */}
                {renderImageDronePopup()}
                {/* manual Pop up */}
                {renderImageManualPopup()}
                {/* close range Pop up */}
                {renderImageCloseRangePopup()}
                {renderPopopThumbnail()}
                {renderDrawerReplaceImage()}
                {renderDrawerShowLog()}
                {renderDrawer()}

                <canvas id="canvasElevationDetail" style={{ display: 'none' }}></canvas>
                <canvas id="canvasDroneReplace" style={{ display: 'none' }}></canvas>
                <canvas id="canvas" style={{ display: 'none' }}></canvas>
              </>
            )}
          </PaddingAuto>
          <canvas id="canvasThumbnail" style={{ display: 'none' }}></canvas>
        </>
      )}
    </>
  )
}

export default ElevationDetails
